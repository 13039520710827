<route>
{
  "meta": {
    "auth": "lottery"
  }
}
</route>

<template>
  <div>
    <el-card class="searchContainer">
      <div class="desc">筛选查询</div>
      <el-row :gutter="20" class="query">
        <el-col :span="8">
          <span class="searchText">用户ID</span>
          <el-input
            class="searchInput"
            oninput="value=value.replace(/[^0-9]/g,'')"
            placeholder="请输入用户ID"
            v-model="searchValue.memberId"
            clearable
            @keyup.enter.native="getList"
          />
        </el-col>

        <el-col :span="8">
          <span class="searchText">联系号码</span>
          <el-input
            class="searchInput"
            oninput="value=value.replace(/[^0-9]/g,'')"
            placeholder="请输入联系号码"
            v-model="searchValue.memberMobile"
            clearable
            @keyup.enter.native="getList"
          />
        </el-col>
      </el-row>
      <el-row :gutter="20" class="query">
        <el-col :span="8">
          <span class="searchText">抽奖码个数</span>
          <el-input
            class="searchInput"
            oninput="value=value.replace(/[^0-9]/g,'')"
            placeholder="请输入抽奖码个数"
            v-model="searchValue.inviteMemberNum"
            clearable
            @keyup.enter.native="getList"
          />
        </el-col>
        <el-col :span="8">
          <span class="searchText">抽奖结果</span>
          <el-select class="searchInput" v-model="searchValue.winning" placeholder="请选择抽奖状态">
            <el-option label="全部" value="">全部 </el-option>
            <el-option label="未开奖" value="0">未中奖 </el-option>
            <el-option label="已开奖" value="1">已中奖 </el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-button type="info" @click="resetSearch()">重置</el-button>
          <el-button type="primary" @click="getList()"> 搜索</el-button></el-col
        >
      </el-row>
    </el-card>
    <el-card class="tableContainer">
      <div slot="header" class="clearfix">
        <span>参与记录</span>
      </div>

      <el-table :data="list" v-loading="loading" style="width: 100%">
        <el-table-column align="center" prop="memberId" label="用户ID"> </el-table-column>
        <el-table-column align="left" prop="info" label="用户信息">
          <template slot-scope="scope">
            <div class="wrapper">
              <img class="avatar" :src="scope.row.memberAvatarUrl | thumbSmall" />
              <span class="name"> {{ scope.row.memberName }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="memberMobile" label="联系方式"> </el-table-column>
        <el-table-column align="center" prop="inviteMemberNum" label="邀请参与人数"> </el-table-column>
        <el-table-column align="center" prop="winningRatio" label="抽奖概率"> </el-table-column>
        <el-table-column align="center" label="抽奖结果">
          <template slot-scope="scope">
            <div>
              <span> {{ scope.row.winning ? '中奖' : '未中奖' }}</span>
            </div>
          </template></el-table-column
        >
      </el-table>
      <div class="footer">
        <el-pagination
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          class="pagination"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import { pagination } from '@/mixin/pagination'
export default {
  mixins: [pagination],
  data() {
    return {
      data: [this.$store.getters['global/day7Int'], this.$store.getters['global/todayInt']],
      searchValue: {
        memberId: '',
        winning: '',
        memberMobile: '',
        inviteMemberNum: '',
        overlordMealId: this.$route.query.id
      },
      list: [],
      loading: false,
      lotteryInfo: { show: false, id: '' }
    }
  },
  methods: {
    // 请求后端获取用户列表
    getList(val) {
      this.loading = true

      this.currentPage = val === true ? this.currentPage : 1
      let params = {
        params: {
          page: this.currentPage,
          size: this.pageSize,
          ...this.searchValue
        },
        headers: {
          ['Bm-Area-Id']: this.$store.state.lottery.currentId
        }
      }

      this.$api.lottery
        .lottery('record', params)
        .then(res => {
          this.list = res.list
          this.total = parseInt(res.total)
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    },

    resetSearch() {
      this.searchValue = {
        memberId: '',
        winning: '',
        memberMobile: '',
        inviteMemberNum: 0,
        page: 1,
        size: 10,
        overlordMealId: this.$route.query.id
      }
      this.data = [this.$store.getters['global/day7Int'], this.$store.getters['global/todayInt']]
      this.getList()
    }
  }
}
</script>

<style lang="less" scoped>
.platform-data {
  display: flex;
}

.searchContainer .searchInput {
  width: 180px;
}

.searchContainer .searchInputRange {
  width: 80px;
}
.query {
  margin-top: 20px;
}
.wrapper {
  display: flex;
  align-items: center;
  .avatar {
    display: inline-block;
    vertical-align: middle;
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
  .name {
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
</style>
